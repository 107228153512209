<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en"/>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, rgb(2, 247, 229) 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="150" height="150" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp APK
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.30</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p v-if="!$global.isMobileDevice()" class="has-text-align-center d">
                      GBWhatsApp Pro is a modified version of the official WhatsApp. The
                      difference between GB WhatsApp and WhatsApp is "features.",
                      It means that the GB WhatsApp app provides more specific features than
                      WhatsApp, for example, Auto Reply, DND Mod, Anti-revoke
                      message, Emoji Effective, etc. so more people would like to use the GB
                      WhatsApp apk instead of the official WhatsApp.
                    </p>
                    <p v-if="$global.isMobileDevice()" class="has-text-align-center d">
                      GB WhatsApp provides more specific features than WhatsApp, such as: Auto Reply, DND Mode, Anti-revoke Message, etc.
                    </p>


                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a class="wp-block-button__link wp-element-button" href="https://www.gbwhatsapp.chat/downloadpage/">Download GBWhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1</span> What is GB WhatsApp & GB WhatsApp Pro?</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2</span> Why people prefer using GB WhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3</span> What is the difference between GB WhatsApp Pro and WhatsApp?</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4</span> GB WhatsApp Features</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5</span> GB WhatsApp Apk Latest Version</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6</span> GB WhatsApp Download Guide</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7</span> Screenshot</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8</span> GB WhatsApp Update</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9</span> GB WhatsApp Old Versions and Changelog</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10</span> Is GBWhatsApp safe?</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11</span> Will GB WhatsApp have Data Security issues?</a>
                      </li>
                      <li>
                        <a href="#TBC_11"><span class="toc_number toc_depth_11">12</span> How to fix "You need the official WhatsApp to log in" problem?</a>
                      </li>
                      <li>
                        <a href="#TBC_12"><span class="toc_number toc_depth_12">13</span> How to spy on others WhatsApp without touching their phone?</a>
                      </li>
                      <li>
                        <a href="#TBC_13"><span class="toc_number toc_depth_13">14</span> FAQ About GBWhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_14"><span class="toc_number toc_depth_14">15</span> Conclusion</a>
                      </li>
                    </ul>

                  </section>
                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0">What is GB WhatsApp and GBWhatsApp Pro? </span>
                </h2>

                <p>
                  You must know WhatsApp, right? And GB WhatsApp is actually one of the many
                  versions of WhatsApp with rich features. It just has
                  many more features than WhatsApp, such as stealth, automatic reply, selecting
                  more people to send messages at once, and even
                  sending larger video files. GB WhatsApp Pro is another version of WhatsApp. Some
                  other websites may have told you that GB WhatsApp
                  and GB WhatsApp Pro are different apps. The difference between them is that they
                  have some different features; actually, they are
                  the same apk, so you can download whichever one you want.
                </p>
                <h2 class="h">
                  <span id="TBC_1">Why people prefer using GB WhatsApp? </span>
                </h2>

                <p>
                  Essentially, in terms of looks and structure, there isn’t much difference
                  between Original WhatsApp, <a class="jump-url" href="https://gbapks.com.pk/">GB WhatsApp</a>, or <a href="https://www.gbwhatsapp.chat/" class="jump-url">GB WhatsApp Pro</a>
                  , or even
                  FM WhatsApp. As you know,
                  the main difference between GB WhatsApp and Whatsapp is that GB WhatsApp has
                  more features, so that is why people
                  prefer to use GB WhatsApp. The most recent version of GBWhatsApp is easily
                  accessible on Android smartphones, and anyone
                  interested in using it can do so. Let me introduce some key highlines from GB
                  WhatsApp.
                </p>

                <h2 class="h">
                  <span id="TBC_2"> What is the difference between GB WhatsApp pro and WhatsApp?
                  </span>
                </h2>
                <p>
                  If you still don't know whether to download and install Whatsapp GB for use or
                  not, let me make their differences clearer. Please
                  refer to the comparison table below.
                </p>

                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>GBWhatsApp Pro</th>
                        <th>WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>DND Mode</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Calls Disable</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>File Sending Limit</td>
                        <td>2GB</td>
                        <td>2GB</td>
                      </tr>
                      <tr>
                        <td>Forwarding Limit</td>
                        <td>Unlimited Chats</td>
                        <td>5 Chats</td>
                      </tr>
                      <tr>
                        <td>Status Length</td>
                        <td>255</td>
                        <td>139</td>
                      </tr>
                      <tr>
                        <td>Save Status</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Auto Message</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Bulk Message</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Themes</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Online Status</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Custom Font</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Anti-Delete</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Security Lock</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Icon Change</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_3"> GB WhatsApp Features </span>
                </h2>

                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">
                      Privacy Options
                    </h3>

                    <p>
                      GBWhatsApp Pro includes additional privacy settings that aren’t available in
                      the official app. These allow you to hide/show
                      your online status, hide/show blue ticks, hide/show double ticks, type
                      status, etc.
                    </p>

                    <h3 class="h3">
                      Change Themes
                    </h3>

                    <p>
                      Themes were included in the GBWhatsApp APK, and they are now one of the mod's best features. They allow you to change your app layout whenever you like, as many times as you like. Themes reflect your personalities, in GB WhatsApp Pro, you can set stylish themes instead of default dark and light ones.
                    </p>

                    <h3 class="h3">
                      Bulk Sender
                    </h3>

                    <p>
                      This tool allows you to send Bulk Message Sending Features, Which means you
                      can send Unlimited messages to contact and prank
                      your friends with this amazing tool.
                    </p>

                    <h3 class="h3">
                      Hide your status Seen
                    </h3>

                    <p>
                      You can check and download Your Friend&#8217;s status at any time, But if
                      wanted to don&#8217;t let them know that you have
                      seen their status, You can simply do that with a click.
                    </p>

                    <h3 class="h3">
                      DND Mode
                    </h3>

                    <p>
                      It has an inbuilt Do Not Disturb Mode, If you are using some other app on
                      your Android phone and don’t want to get disturbed
                      by Whatsapp messages then you can use the DND feature to disable the
                      internet connection for GB Whatsapp Pro only.
                    </p>

                    <h3 class="h3">
                      App Lock
                    </h3>

                    <p>
                      The app lock allows you to require a Pattern, PIN, Password, or Fingerprint
                      to enter the app and access your messages. This
                      adds a layer of security and keeps your messages hidden even from people who
                      have access to your phone. PIN and Password can
                      be shown while you type them if you wish.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">
                      Auto Reply
                    </h3>

                    <p>
                      Firstly, you can use this auto-reply feature when you want to reply to any
                      of your friends at any time. You can use this for
                      promoting your Business by sending unlimited Bulk messages to any chat or
                      Group.
                    </p>

                    <h3 class="h3">
                      Anti-revoke
                    </h3>

                    <p>
                      Comes with an Anti-revoke messages feature, Which means if someone Sends any
                      type of message and then deletes everyone from
                      their side, You still can see those deleted messages by default.
                    </p>

                    <h3 class="h3">
                      Send Maximum Pictures
                    </h3>

                    <p>
                      Additionally, as compared to official Whatsapp you can send more than 90
                      pictures at a time. Also, you can send a 50 MB video
                      clip and a 100 MB audio clip to your contact.
                    </p>

                    <h3 class="h3">
                      Send High Image Quality
                    </h3>

                    <p>
                      Sometimes we have to send Images in Original Quality, and for that, we use
                      the Document Option. Now GBWhatsApp Pro Allows you
                      to end Images in the Highest Quality.
                    </p>

                    <h3 class="h3">
                      Download Status
                    </h3>

                    <p>
                      Another great feature of this app is that you can download pictures and
                      videos of statuses uploaded by other contacts. Now you
                      don&#8217;t have to use any third-party Application, Save any kind of Status
                      from your contact list.
                    </p>

                    <h3 class="h3">
                      Filter messages
                    </h3>

                    <p>
                      The GB WhatsApp Pro APK is formulated with the Filter Messages feature which
                      provides the user with an option to clear chat
                      which can also filter your messages.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_4"> GB WhatsApp Apk Latest Version </span>
                </h2>
                <div class="wp-block-image ic">
                  <figure class="aligncenter size-full">
                    <img decoding="async" width="512" height="512" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                  </figure>
                </div>
                <p class="has-text-align-center">
                  GBWhatsApp App is a popular version of WhatsApp that offers a wealth of features and
                  customization options. Users can change the theme of
                  the application, the appearance of the chat box, and even the font type.
                  Consider the fantastic design of the GBWA Pro, which has
                  a modern, thin interface that is simple and quick to use. Furthermore, users can
                  determine who can view their profile images,
                  status, and online presence by altering the privacy settings in GBWhatsApp Pro. App
                  and conversation locks are also included in GBWhatsApp
                  Pro. Users can use this feature to protect their messages and data by locking
                  individual chats or entire applications. If you want
                  to konw the GB WhatsApp APK download way, just click the following buttun.
                </p>

                <figure class="wp-block-table is-style-regular">
                  <table class="has-fixed-layout">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>GBWhatsApp Pro</td>
                      </tr>
                      <tr>
                        <td>Version</td>
                        <td>
                          <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                            18.30
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>77M</td>
                      </tr>
                      <tr>
                        <td>Developer</td>
                        <td>AlexMODS</td>
                      </tr>
                      <tr>
                        <td>Updated</td>
                        <td>2 Days Ago</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>
                <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                  <div class="wp-block-button">
                    <a class="wp-block-button__link wp-element-button" href="https://www.gbwhatsapp.chat/downloadpage/">GB WhatsApp Download</a>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_5"> GB WhatsApp Download Guide </span>
                </h2>
                <h3>
                  Requirements
                </h3>
                <ol class="ol">
                  <li>Android phone. GB WhatsApp for iPhone is not available.</li>
                  <li>Phone storage should be over 200 MB.</li>
                  <li>A stable internet is necessary.</li>
                </ol>
                <h3>
                  Download GB WhatsApp APK Steps
                </h3>
                <ul class="ul">
                  <li>Step 1: Enable "install unknown apps"</li>
                  <li>Step 2: Click the button below to download GB WhatsApp APK:</li>
                  <li>Step 3: Give permissions and install this app. <br> <br>
                    <div class="wp-block-image">
                      <figure class="aligncenter size-40">
                        <picture>
                          <source srcset="../assets/install-unknown-apps.webp" type="image/webp">
                          <img decoding="async" loading="lazy" src="../assets/install-unknown-apps.webp" alt="install unknown apps">
                        </picture>
                      </figure>
                    </div>
                  </li>
                </ul>

                <h2 class="h">
                  <span id="TBC_6"> Screenshot </span>
                </h2>

                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/Screenshot.webp" type="image/webp">
                      <!-- <img decoding="async" loading="lazy" width="1000" height="563"
                          src="../assets/Screenshot.webp" alt="Screenshot" class="wp-image-48"> -->
                      <img decoding="async" loading="lazy" src="../assets/Screenshot.webp" alt="Screenshot" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_7"> GB WhatsApp Update </span>
                </h2>

                <p>
                  If you or your friends who have downloaded <a class="jump-url" href="https://gbapks.com.pk/gb-whatsapp-old-version">an older version of GBWhatsApp</a> and
                  want to update it to the latest version, There are
                  two GB WhatsApp update options provided to you..
                </p>
                <ul class="ul">
                  <li>
                    Method 1: Visit the official GBWhatsApp APK download website to obtain the
                    installation file for the latest version. Because it
                    always keeps up to the GB WhatsApp latest version on website homepage. After GB
                    WhatsApp APK file downloaded, just follow the app prompts to
                    operate it.
                  </li>
                  <li>
                    Method 2: Open GBWhatsApp and navigate to the settings interface. You can
                    see the current GBWhatsApp version and If an update
                    is necessary, simply tap on the "<strong>GB WhatsApp Update</strong>" button and wait for the
                    installation file to finish downloading. Tap the
                    "Install" button to start the installation update process.
                  </li>
                </ul>
                
                <p>
                  Remember to regularly update your GB WhatsApp APK to ensure optimal performance and
                  security.
                </p>

                <h2 class="h">
                  <span id="TBC_8"> GB WhatsApp Old Versions and Changelog </span>
                </h2>
                <div class="old-item">
                  <h3>GB WhatsApp v18.30-2.24.25.71 <span style="color: red;">New</span></h3>
                  <p>
                    Size: 83 MB <br>
                    Improved: Anti-Ban Protection <br>
                    Improved: Linking Devices Feature <br>
                    Fixed All Crash Problems <br>
                  </p>
                </div>
                <div class="old-item">
                  <h3><a href="https://gbpps.com.pk/" class="jump-url">GB WhatsApp v18.20</a>-2.24.16.76</h3>
                  <p>
                    Size: 77 MB <br>
                    Released: Oct 2024 <br>
                    Improved Anti-Ban Protection  <br>
                    Many Fixes And New Features  <br>
                  </p>
                </div>
                <div class="old-item">
                  <h3>GB WhatsApp v18.10</h3>
                  <p>
                    Size: 77 MB <br>
                    Released: September 2024 <br>
                    Improved Anti-Ban Protection
                  </p>
                </div>
                <div class="old-item">
                  <h3>GB WhatsApp v18.00</h3>
                  <p>
                    Size: 75 MB <br>
                    Released: August 2024 <br>
                    Improved Anti-Ban Protection & Many Fixes
                  </p>
                </div>

                <h2 class="h">
                  <span id="TBC_9"> Is GBWhatsApp safe? </span>
                </h2>

                <p>
                  That's what most people are concerned about. <br>
                  After receiving feedback from customers and personally downloading and using
                  APK, I have found that most people have not been
                  subjected to any unsafe attacks, and they are all using it well. However, a
                  small number of people may encounter issues with
                  unsuccessful login or data loss. <br>
                  After my trial, I found that as long as you download GB WhatsApp app from a <strong>secure website</strong>, you
                  will not encounter any unsafe issues. And even if
                  you are unable to log in successfully, as long as you uninstall the old app and
                  download the new one again, it can solve the
                  problem.
                </p>

                <h2 class="h">
                  <span id="TBC_10"> Will GB WhatsApp have Data Security issues？ </span>
                </h2>

                <p>
                  The other question that people are more concerned about is the data leakage
                  issue. Due to the particularity of GB WhatsApp, it can
                  only be downloaded on the website, and then there are many websites that provide
                  channels to download GB WhatsApp apk, but there
                  are some insecure websites, so you must pay attention to distinguish between
                  downloading safe versions. <br>
                  So how do you tell? For example, if you download an apk with only one GB
                  WhatsApp app and no other unrelated app bindings, then it
                  is probably safe. <br> In short, I recommend this website to download the
                  installation package, which is in strict compliance with
                  Google regulations, does not collect user personal information, and is a secure
                  version, so there will be no data leakage
                  problems. Of course, if you want to use GBWhatsApp, please also strictly abide
                  by the
                  <a class="jump-url" href="https://www.whatsapp.com/">WhatsApp rules</a>
                  . Do not do illegal things; otherwise, the account will be blocked, and we will
                  not be responsible.<br>
                  Oh, there are tips that I need to remind you of. When you are using GB WhatsApp,
                  remember to back up your data so that when you
                  update your app or change your phone, your history will be in sync
                </p>

                <h2 class="h">
                  <span id="TBC_11"> How to fix "You need the official WhatsApp to log in" problem?
                  </span>
                </h2>

                <p>
                  This notice first appeared in GB WhatsApp v17.70 due to WhatsApp officials implementing strict measures to block WhatsApp Mods. If you receive this notice, it is recommended to switch to the official WhatsApp to prevent your account from being banned.
                </p>
                <p>
                  You can expect GB WhatsApp developer AlexMods to upgrade systems and release anti-ban enhanced version. Actually the newly updated GB WhatsApp v18.30 has optimized the anti-ban protection. However, This Update Can't Work With Banned Numbers. The only thing you can do is create an account and enjoy those customized features again.
                </p>

                <figure class="aligncenter">
                  <picture>
                    <source srcset="../assets/tbc-0-1.webp" type="image/webp">
                    <img decoding="async" loading="lazy" src="../assets/tbc-0-1.webp" alt="gbwhatsapp login problem fixed in latest version" class="wp-image-48">
                  </picture>
                </figure>


                <h2 class="h">
                  <span id="TBC_12"> How to spy on others whatsapp without touching their phone?
                  </span>
                </h2>

                <p>
                  Friends who use GB WhatsApp know that GBWhatsApp offers many features that the
                  official WhatsApp does not have, which is why it is so popular. However,
                  unfortunately, based on personal privacy protection policies, GBWhatsApp
                  currently does not have a feature to spy on others' WhatsApp chats.
                </p>

                <p>But don't worry, here are some methods that might help you. </p>

                <p>
                  To monitor someone else's WhatsApp chat records, you need to use other apps.
                  Let's see <a class="jump-url" href="https://www.gbwhatsapp.chat/blog-5/">"The 10 best apps
                    for spying someone else's WhatsApp"</a>.
                </p>

                <h2 class="h">
                  <span id="TBC_13"> FAQ About GBWhatsApp Pro </span>
                </h2>

                <div class="schema-faq wp-block-yoast-faq-block">
                  <div v-for="(faq, index) in faqs" :key="index" class="schema-faq-section">
                    <strong class="schema-faq-question" @click="toggleFaq(index)">
                      {{ faq.question }}
                    </strong>
                    <p class="schema-faq-answer" v-show="faq.open" v-html="faq.answer">
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_14"> Conclusion </span>
                </h2>

                <p>
                  GBWhatsApp Pro is a great app for anyone who wants a more personalised and
                  feature-rich WhatsApp experience. With so many
                  features, it's no surprise that millions of people around the world prefer this
                  app to the original WhatsApp. For future updates,
                  please save or bookmark this page (<a href="https://www.gbwhatsapp.chat/" class="jump-url">https://www.gbwhatsapp.chat</a>
                  ). If you want to use a more feature-rich version of WhatsApp, GBWhatsApp, Yo
                  Whatsapp, and <a href="https://gbapks.com.ru/" class="jump-url">WhatsApp Plus</a> are also great options.
                  Let's share it with your friends now!
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="comment-box">
    <div class="title">
      Leave a Comment
    </div>
    <input v-model="comment.name" type="text" class="nameClass" placeholder="your name">
    <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
    <textarea v-model="comment.content" class="contentClass" />
    <button class="submitClass" @click="submitClick">
        Submit
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList"
              href="https://www.gbwhatsapp.chat/">https://www.gbwhatsapp.chat/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://www.gbwhatsapp.chat/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      href="https://www.gbwhatsapp.chat/downloadpage/">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import HeadNav from '@/components/HeadNav.vue';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
export default {
  name: 'Home',
  components: {
    HeadNav
  },
  data() {
    return {
      pageName: 'home',
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'po',
          val: '🇵🇹 Português'
        },
        {
          lang: 'tr',
          val: '🇹🇷 Türkçe'
        },
        {
          lang: 'in',
          val: '🇮🇳 हिंदी'
        },
      ],
      tableHide: true,
      faqs: [
        {
          "question": "How can I recover my banned GB WhatsApp account?",
          "answer": "The banned account can't use GB WhatsApp latest version anymore. You can change an account to log in to the app.",
          "open": false
        },
        {
          "question": "Can I use GB WhatsApp on iPhone or Tablets?",
          "answer": "Sorry, there is no official GB WhatsApp iOS version for users to download directly.",
          "open": false
        },
        {
          "question": "Is GB WhatsApp available for PC?",
          "answer": "Users can download Android Emulator on PC, then install GB WhatsApp APK on PC.",
          "open": false
        },
        {
          "question": "Can I use GBWhatsApp and Official WhatsApp on the same device?",
          "answer": "Yes, you can use both on the same device with different phone numbers.",
          "open": false
        },
        {
          "question": "Can I transfer all of My WhatsApp data to GBWhatsApp?",
          "answer": "Yes, you can migrate from Official WhatsApp to GBWhatsApp Pro. Remember to <a class='jump-url' href='/blog-2'>back up WhatsApp data to Google Drive</a> first.",
          "open": false
        },
        {
          "question": "Alternatives to GBWhatsApp Pro",
          "answer": "There are many alternatives to GBWhatsApp. Here are some of them:\n– WhatsApp Plus\n– OG WhatsApp\n– FM WhatsApp\n– Yo WhatsApp\n– Aero WhatsApp",
          "open": false
        },
        {
          "question": "Can I download WhatsApp status directly?",
          "answer": "Yes, you can directly download anyone's status using GBWhatsApp.",
          "open": false
        },
        {
          "question": "Can I hide GBWhatsApp from my phone?",
          "answer": 'You can\'t completely hide it, but you can change the icon of <a href="https://gbwhatapks.com/gbwhatsapp/" class="jump-url">GB WhatsApp</a>. So, no one can determine whether it is WhatsApp or not.',
          "open": false
        },
        {
          "question": "What is the Most Secure Feature of The GBWhatsApp Pro?",
          "answer": "Fingerprint Lock is the most secure feature of GBWhatsAp Pro.",
          "open": false
        },
        {
          "question": "Does WhatsApp unban a banned GBWhatsApp account?",
          "answer": "If your account was temporarily banned, you should transfer to WhatsApp and contact them to unlock your account. But if your account was permanently banned, this account can't log in to GB WhatsApp anymore.",
          "open": false
        },
        {
          "question": "Can we use GB WhatsApp for Business Purposes?",
          "answer": "You can set auto-reply messages in GB WhatsApp, but the Store option will not be available.",
          "open": false
        }
      ]
    };
  },
  computed: {
    allCommentList() {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
    showText() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList() {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted() {
    this.getOfficalApk();
    document.documentElement.lang = this.selectedLanguage;

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    // submitClick() {
    //   this.$Dialog
    //     .confirm({
    //       title: 'Tip',
    //       message: 'Your comment has been submitted successfully and will be displayed below after review.',
    //       confirmButtonText: 'confirm',
    //       cancelButtonText: 'cancel',
    //     })
    //     .then(() => {
    //       this.comment.name = '';
    //       this.comment.email = '';
    //       this.comment.content = '';
    //     })
    //     .catch(() => {
    //       console.log('取消');
    //     });
    // },
    submitClick() {
      alert('Your comment has been submitted successfully and will be displayed below after review.');
      this.comment.name = '';
      this.comment.email = '';
      this.comment.content = '';
    },

    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    toggleFaq(index) {
      this.faqs[index].open = !this.faqs[index].open;
    }
  },
};
</script>